import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faImage } from '@fortawesome/free-solid-svg-icons';

const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 768); // Abierto en PC, cerrado en móvil

  const toggleNavbar = () => setIsOpen(!isOpen);

  return (
    <>
      {/* Botón de toggle fuera de la Navbar */}
      <button className="navbar-toggle" onClick={toggleNavbar}>
        ☰
      </button>
      <div className={`navbar ${isOpen ? 'open' : 'closed'}`}>
        <nav className="navbar-content">
          <ul>
            <li>
              <Link to="/" className="nav-link">
              <FontAwesomeIcon style={{marginRight: "1rem"}} icon={faHome} />
                {isOpen && <span>Inicio</span>}
              </Link>
            </li>

            <li>
              <Link to="/carnets" className="nav-link">
              <FontAwesomeIcon style={{marginRight: "1rem"}} icon={faImage} />
                {isOpen && <span>Carnets</span>}
              </Link>
            </li>


          </ul>
        </nav>
      </div>
    </>
  );
};

export default AppNavbar;
