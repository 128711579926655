import React from 'react';

const Nosotros = () => {
  return (
    <div>
      <h2>Nosotros</h2>
      <p>Bienvenido a Gestión Aleron Club.</p>
    </div>
  );
};

export default Nosotros;
