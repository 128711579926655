import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './carnet.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Carnets = () => {
    const [solicitudes, setSolicitudes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        fecha: '',
        apto: '',
        creado: ''
    });

    const itemsPerPage = 10;

    useEffect(() => {
        axios.get('https://backaleron.aleronclub.com/api/solicitud-carnet')
            .then(res => setSolicitudes(res.data))
            .catch(err => console.error('Error al obtener los datos:', err));
    }, []);

    const handleToggle = (id, field, value) => {
        axios.put(`https://backaleron.aleronclub.com/api/solicitud-carnet/${id}`, {
            [field]: value
        })
        .then(res => {
            setSolicitudes(prev => prev.map(item => item.id === id ? res.data : item));
        })
        .catch(err => console.error('Error al actualizar:', err));
    };

    const filteredItems = solicitudes.filter(s => {
        const matchFecha = filters.fecha ? s.createdAt.startsWith(filters.fecha) : true;
        const matchApto = filters.apto !== '' ? s.aptoParaCarnet === (filters.apto === 'true') : true;
        const matchCreado = filters.creado !== '' ? s.carnetCreado === (filters.creado === 'true') : true;
        return matchFecha && matchApto && matchCreado;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
        setCurrentPage(1);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredItems);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Solicitudes");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(dataBlob, 'solicitudes-carnets.xlsx');
    };

    return (
        <div style={{marginTop: "5rem"}} className="table-container">
            {/* Filtros y botón exportar */}
            <div className="filters">
                <div className="filter-item">
                    <label>Filtrar por Fecha:</label>
                    <input 
                        type="date" 
                        name="fecha" 
                        value={filters.fecha} 
                        onChange={handleFilterChange} 
                    />
                </div>
                <div className="filter-item">
                    <label>Apto para carnet:</label>
                    <select name="apto" value={filters.apto} onChange={handleFilterChange}>
                        <option value="">Todos</option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="filter-item">
                    <label>Carnet creado:</label>
                    <select name="creado" value={filters.creado} onChange={handleFilterChange}>
                        <option value="">Todos</option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="filter-item">
                    <button onClick={exportToExcel} className="export-btn">Exportar a Excel</button>
                </div>
            </div>

            {/* Tabla */}
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Email</th>
                        <th>¿Apto para carnet?</th>
                        <th>¿Carnet creado?</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(s => (
                        <tr  key={s.id}>
                            <td data-label="Fecha">{new Date(s.createdAt).toLocaleString()}</td>
                            <td data-label="Email">{s.email}</td>
                            <td data-label="¿Apto para carnet?">
                                <input 
                                    type="checkbox" 
                                    checked={s.aptoParaCarnet} 
                                    onChange={e => handleToggle(s.id, 'aptoParaCarnet', e.target.checked)}
                                />
                            </td>
                            <td data-label="¿Carnet creado?">
                                <input 
                                    type="checkbox" 
                                    checked={s.carnetCreado} 
                                    onChange={e => handleToggle(s.id, 'carnetCreado', e.target.checked)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Paginación */}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                    <button 
                        key={i + 1} 
                        onClick={() => setCurrentPage(i + 1)}
                        className={currentPage === i + 1 ? 'active' : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Carnets;
