import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

// Configura el elemento raíz para el modal
Modal.setAppElement('#root');

// Estilos personalizados
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const UploadButton = styled.button`
  background-color: #00247D;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;

  &:hover {
    background-color: #001A59;
  }
`;

const ModalContent = styled(Modal)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  margin: auto;
  align-items: center;

  h2 {
    margin-bottom: 20px;
    color: #00247D;
  }

  input, label {
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  button {
    margin-top: 10px;
  }
`;

const Button = styled.button`
  background-color: #00247D;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;

  &:hover {
    background-color: #001A59;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Media = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [mediaTitle, setMediaTitle] = useState('');
  const [mediaLink, setMediaLink] = useState('');

  // Función para abrir el modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalIsOpen(false);
    setImageFile(null); // Limpiar el archivo subido al cerrar
    setMediaTitle('');  // Limpiar el título
    setMediaLink('');   // Limpiar el link
  };

  // Manejar la carga del archivo de imagen
  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  // Guardar los datos del modal
  const handleSave = () => {
    if (!imageFile || !mediaTitle || !mediaLink) {
      alert('Por favor, completa todos los campos.');
      return;
    }

    // Aquí puedes implementar la lógica para subir la imagen y guardar los datos en el servidor

    console.log('Imagen:', imageFile);
    console.log('Título:', mediaTitle);
    console.log('Link:', mediaLink);

    // Cerrar el modal y limpiar los campos
    closeModal();
  };

  return (
    <PageContainer>
      <h1>Gestión de Media</h1>
      <UploadButton onClick={openModal}>Subir Media</UploadButton>

      <ModalContent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Subir Media"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro
            display: 'flex', // Asegurarse de que el modal esté centrado
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            inset: 'auto', // Elimina valores de posición por defecto
          },
        }}
      >
        <h2>Subir Media</h2>
        <label htmlFor="file">Seleccionar imagen:</label>
        <input type="file" id="file" accept="image/*" onChange={handleImageChange} />

        <label htmlFor="title">Título:</label>
        <input
          type="text"
          id="title"
          placeholder="Título de la imagen"
          value={mediaTitle}
          onChange={(e) => setMediaTitle(e.target.value)}
        />

        <label htmlFor="link">Link:</label>
        <input
          type="text"
          id="link"
          placeholder="Enlace"
          value={mediaLink}
          onChange={(e) => setMediaLink(e.target.value)}
        />

        <div>
          <Button style={{ marginRight: "25px" }} onClick={handleSave}>Guardar</Button>
          <Button onClick={closeModal} style={{ backgroundColor: '#ccc', color: '#000' }}>
            Cancelar
          </Button>
        </div>
      </ModalContent>
    </PageContainer>
  );
};

export default Media;
