import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';

const Home = () => {
  const [solicitudesCarnets, setSolicitudesCarnets] = useState(0);
  const [carnetsAptos, setCarnetsAptos] = useState(0);
  const [carnetsCreados, setCarnetsCreados] = useState(0);

  const fetchData = async () => {
    try {
      // Obtener el total de solicitudes
      const responseSolicitudes = await fetch('https://backaleron.aleronclub.com/api/solicitud-carnet');
      const dataSolicitudes = await responseSolicitudes.json();
      setSolicitudesCarnets(Array.isArray(dataSolicitudes) ? dataSolicitudes.length : dataSolicitudes);

      // Obtener carnets aptos
      const responseAptos = await fetch('https://backaleron.aleronclub.com/api/solicitud-carnet/carnets-aptos');
      const dataAptos = await responseAptos.json();
      setCarnetsAptos(dataAptos);

      // Obtener carnets creados
      const responseCreados = await fetch('https://backaleron.aleronclub.com/api/solicitud-carnet/carnets-creados');
      const dataCreados = await responseCreados.json();
      setCarnetsCreados(dataCreados);

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="lg" className="main-content">
      {/* Logo */}
      <Box display="flex" justifyContent="center" my={4}>
        <img src="./images/LogoAzul.png" alt="Logo Aleron Club" style={{ maxWidth: '200px' }} />
      </Box>

      {/* Cards */}
      <Grid container spacing={4} justifyContent="center" marginTop="4rem">
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Solicitudes de carnets</Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Total de solicitudes recibidas: <span style={{ fontWeight: 'bold' }}>{solicitudesCarnets}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Carnets aptos</Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Carnets marcados como aptos: <span style={{ fontWeight: 'bold' }}>{carnetsAptos}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Carnets creados</Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Total de carnets generados: <span style={{ fontWeight: 'bold' }}>{carnetsCreados}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
